import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import 'bootstrap/dist/css/bootstrap.min.css';

import "../styles/style.scss"
import "../styles/main.scss"
import Layout from '../components/layout';
import SEO from '../components/seo';
import Slot from '../components/slot/slot';
import { SlotModel } from '../shared/models/slot.model';
import { CarouselModel } from '../shared/models/carousel.model';
import { SectionModel } from '../shared/models/section.model';
import { ArticlesListModel } from '../shared/models/articles-list.model';
import { CallToActionModel } from '../shared/models/call-to-action.model';
import { VideoModel } from '../shared/models/video.model';
import { ImageModel } from '../shared/models/image.model';
import TypeSlot from '../shared/constants/type-slot';
import { getCurrentLang } from '../data/languages'

function HomePage() {
  const data = useStaticQuery(graphql`
  query HomePageQuery {
    site {
        siteMetadata{
            languages {
                defaultLangKey
                langs
            }
        }
    }

    allContentfulPage(filter: {slug: {eq: "home"}}) {
      totalCount
      edges {
        node {
          id
          node_locale
          title
          keywords
          slug
          description {
            description
          }
        }
      }
      nodes {
        node_locale
        slots {
          backgroundClass
          name
          order
          contents {
            __typename
            ...on ContentfulSlotBlocksList{
              title
              description {
                internal {
                  content
                  description
                  ignoreType
                  mediaType
                }
              }
              topics {
                name
                
              }
              blocks {
                name
                backgroundClass
                body {
                  json
                }
              }
            }
            ... on  ContentfulSlotArticlesArticleList{
              id
              showLink
              showPublishedDate
              numberToShow
              title
              positionOfTitle
              showTitle
              articles{
                title
                slug
                publishedDate(formatString: "DD MMMM YYYY")
                primaryImage{
                  fluid {
                    ...GatsbyContentfulFluid_withWebp
                  }
                }
                
              }
            }
            ... on ContentfulSlotCarousel {
              images {
                title
                colorInverted
                richTextOverlay{
                  json
                }
                alignment
                image {
                  file {
                    url
                    fileName
                    contentType
                  }
                  fluid(maxWidth:1300) {
                    ...GatsbyContentfulFluid_withWebp
                  }
                }
              }
            }
            ... on ContentfulSlotImage {
              title
              image {
                file {
                  url
                  fileName
                  contentType
                }
                fluid {
                  ...GatsbyContentfulFluid_withWebp
                }
                id
              }
            }
            ... on ContentfulSlotVideo {
              title
              thumbnail {
                fluid {
                  ...GatsbyContentfulFluid_withWebp
                }
              }
              videoFile {
                file {
                  url
                  fileName
                  contentType
                }
                id
              }
            }
            ... on ContentfulSlotSection {
              title
              sectionId
              subTitle
              imagePosition
              sectionHeading
              isPrimary
              underlineHeader
              body {
                json
              }
              image {
                title
                file {
                  url
                  fileName
                  contentType
                }
                fluid {
                  ...GatsbyContentfulFluid_withWebp
                }
              }
              backgroundClass
              imageWidth
              bodyWidth
              linkUrl
              linkDescription
            }
            ... on ContentfulSlotCallToAction {
              title
              subTitle
              content {
                name
                listItems {
                  linkDescription
                  linkUrl
                  image {
                    file {
                      url
                      fileName
                      contentType
                    }
                    fluid {
                      ...GatsbyContentfulFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`)

  const currentLanguage = getCurrentLang();

  let slotsList = data.allContentfulPage.nodes.find(node => node.node_locale.search(currentLanguage) > -1).slots;
  let page = data.allContentfulPage.edges.find(({ node }) => node.node_locale.search(currentLanguage) > -1).node;

  slotsList = slotsList.map(slot => {
    var slotObj = new SlotModel(slot);
    if (slotObj.content) {
      var contents = slotObj.content[0];
      switch (contents.__typename) {
        case TypeSlot.SlotCarousel:
          slotObj.content = new CarouselModel(contents)
          break;
        case TypeSlot.SlotSection:
          slotObj.content = new SectionModel(contents)
          break;
        case TypeSlot.SlotArticlesList:
          slotObj.content = new ArticlesListModel(contents)
          break;
        case TypeSlot.SlotCTAProduct:
          slotObj.content = new CallToActionModel(contents)
          break;
        case TypeSlot.SlotVideo:
          slotObj.content = new VideoModel(contents)
          break;
        case TypeSlot.SlotImage:
          slotObj.content = new ImageModel(contents)
          break;
        case TypeSlot.SlotBrand:
          break;
        default:
          break;
      }
    }
    return slotObj;
  })

  return <Layout>
    <SEO title={page.title} description={page.description.description} pathname={page.slug} keywords={page.keywords} />
    {slotsList.map((slot, index) => {
      if (slot.content) {
        return <div className={slot.backgroundClass} key={index}><Slot data={slot.content} type={slot.content.typeName} /></div>
      }
      return <div key={index} />
    })}
  </Layout>
}

export default HomePage